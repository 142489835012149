<template>
  <div class="refund-item-wrap refund-item2">
    <div class="refund-body">
      <ul>
        <li
          v-if="!isCod"
          class="refund-amount"
          tabindex="0"
        >
          <CouponArticalPageTips
            :abt-info="{ couponArticalPage }"
            :refund-order="refundForm.data"
            :game-reward-type="refundExpireState"
            :language="language"
            :order="orderInfo"
            scene="partRefund"
          />
        </li>
        <li
          v-if="!isCod"
          class="wallet-card"
        >
          <p
            class="title"
            tabindex="0"
          >
            {{ htmlDecode(language.SHEIN_KEY_PWA_15431) }}
          </p>
          <template v-for="(list, index) in refundPathList">
            <div
              v-if="(!(list.isHideMethod && !list.status) || (list.value == 3 && showAccountBtn)) && list.refundName
              "
              :key="index"
              v-enterkey
              class="wallet-card-item"
              :class="{ 'method-not': !list.status }"
              :aria-checked="list.value == refundForm.refundMethod"
              role="radio"
              tabindex="0"
              @click.stop.prevent="changeMethod(list.value, list.status)"
            >
              <div class="wallet">
                <div
                  class="method-item"
                  :class="{
                    'gift-style': list.value == 1,
                    'wallet-style': list.value == 2,
                    'account-style': list.value == 3
                  }"
                >
                  {{ list.refundName }}
                </div>
                <div
                  class="checkbox"
                  :class="{
                    checked: refundForm.refundMethod == list.value,
                    disabled: !list.status
                  }"
                >
                  <span class="wallet-radio">
                    <i
                      v-show="refundForm.refundMethod == list.value"
                      class="iconfont icon-normal-checked"
                    ></i>
                  </span>
                </div>
              </div>
              <refund-account
                v-if="list.value == 3"
                v-show="showAccountBtn"
                :show="showAccountBtn"
                :lang-text="language"
                :account-info="refundAccountInfo"
                :receiver-country="orderInfo.shipping_country_name"
                :shipping-country-id="orderInfo.shipping_country_id"
                :order="orderInfo"
                :required="showAccountRequired"
                :show-required-tips="showAccountRequiredError"
                @update-account-info="onUpdateRefundAccountInfo"
              />
              <template v-if="!refundAccountInfo.show">
                <div
                  v-if="list.status && list.valid && list.valid[0]"
                  class="des"
                  v-html="list.valid[0].text"
                ></div>
                <div
                  v-else-if="!list.status && list.invalid && list.invalid[0]"
                  class="des"
                  v-html="list.invalid[0].text"
                ></div>
              </template>
            </div>
          </template>
        </li>

        <li class="refund-reason wallet-card">
          <p
            class="title"
            tabindex="0"
          >
            {{ language.SHEIN_KEY_PWA_15433 }}
          </p>
          <p
            v-enterkey
            class="reason-list"
            role="button"
            tabindex="0"
            @click="showRefundReason"
          >
            <span v-html="refundForm.reason"></span>
            <span class="order-refund-fr"> 
              <i class="iconfont icon-down"></i> 
            </span>
          </p>
          <p
            v-if="refundForm.noreason"
            style="color: red; margin-top: 10px"
            tabindex="0"
          >
            {{ language.SHEIN_KEY_PWA_15434 }}
          </p>
        </li>
      </ul>
      <div
        v-if="refundForm.refundMethod"
        class="refund-tips"
        tabindex="0"
      >
        <p class="refund-tips__title">
          {{ language.SHEIN_KEY_PWA_15435 }}:
        </p>
        <p class="refund-tips-1">
          {{ refundTipsDesc }}
          {{ language.SHEIN_KEY_PWA_14597 }}
        </p>
      </div>
      <div class="refund-btn">
        <s-button
          :type="['primary', 'H88PX']"
          width="100%"
          :disabled="submitDisabled"
          @click="handleRefundSubmit"
        >
          {{ language.SHEIN_KEY_PWA_15179 }}
        </s-button>
      </div>
    </div>
    <refundReason
      ref="refundReasonMask"
      :reason="refundReasonDict"
      :language="language"
      :refund-form="refundForm"
      :billno="orderInfo.billno"
      @selectRefoundReason="selectRefoundReason"
    />
    <!-- 提示信息 -->
    <s-dialog
      v-model:visible="resultVisibleMap.visible"
      :append-to-body="true"
    >
      <p>{{ htmlDecode(resultVisibleMap.message) }}</p>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          width="100%"
          @click="handleHideResultDialog"
        >
          {{ language.SHEIN_KEY_PWA_15146 }}
        </s-button>
      </template>
    </s-dialog>

    <s-dialog
      v-model:visible="walletDefaultTips"
      :append-to-body="true"
      :show-close="false"
      @close="showTipsDialog = false"
    >
      <div tabindex="0">
        {{ isMatchPaymentMethodCond ? language.SHEIN_KEY_PWA_22162 : language.SHEIN_KEY_PWA_22163 }}
      </div>
      <div 
        v-if="tipsText"
        class="gift-tips-text"
      >
        {{ tipsText }}
      </div>
      <template #footer>
        <s-button
          :width="'50%'"
          :type="['primary', 'H72PX']"
          @click="walletDefaultTips = false"
        >
          {{ language.SHEIN_KEY_PWA_15723 }}
        </s-button>
      </template>
    </s-dialog>

    <s-dialog
      v-model:visible="updateDefaultPath"
      :show-close="false"
      :append-to-body="true"
      @close="showTipsDialog = false"
    >
      <p
        v-if="changeTextType == 1"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_18050 }}
      </p>
      <p
        v-if="changeTextType == 2"
        tabindex="0"
      >
        {{ isMatchPaymentMethodCond ? language.SHEIN_KEY_PWA_22162 : language.SHEIN_KEY_PWA_22163 }}
      </p>
      <p 
        v-if="tipsText"
        class="gift-tips-text"
      >
        {{ tipsText }}
      </p>
      <template #footer>
        <s-button-group
          hor
          :width="'100%'"
        >
          <s-button-group-item
            :type="['H72PX']"
            @click="cancelUpdateDefaultPath"
          >
            {{ language.SHEIN_KEY_PWA_15223 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['primary', 'H72PX']"
            @click="confirmUpdateDefaultPath"
          >
            {{ language.SHEIN_KEY_PWA_15723 }}
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
    <s-dialog
      v-ada-modal="{
        show: showMinPrice,
        first: () => {
          $refs.minPriceTitle.focus()
        }
      }"
      v-model:visible="showMinPrice"
      :append-to-body="true"
      :immediately-render="true"
      :show-close="false"
    >
      <template #title></template>
      <div
        ref="minPriceTitle"
        tabindex="0"
      >
        {{ htmlDecode(language.SHEIN_KEY_PWA_18531) }}
      </div>
      <template #footer>
        <s-button-group
          :num="2"
          :width="'100%'"
        >
          <s-button-group-item
            :type="['H72PX']"
            @click="showMinPrice = false"
          >
            {{ language.SHEIN_KEY_PWA_15254 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['primary', 'H72PX']"
            @click="handleRefundHidden"
          >
            {{ language.SHEIN_KEY_PWA_15883 }}
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
    <s-dialog
      v-model:visible="showCouponArticalPageDig"
      :append-to-body="true"
    >
      <p>{{ htmlDecode(language.SHEIN_KEY_PWA_21703) }}</p>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          width="100%"
          @click="showCouponArticalPageDig = false"
        >
          {{ language.SHEIN_KEY_PWA_15146 }}
        </s-button>
      </template>
    </s-dialog>
    <!-- 礼品卡临期提示 -->
    <CommonTipsDialog
      v-if="!walletDefaultTips && !updateDefaultPath"
      v-model:visible="showTipsDialog"
      :append-to-body="true"
      :content="tipsText"
      :btn-type="['primary', 'H56PX']"
      :btn-text="language.SHEIN_KEY_PWA_28846"
    />
    <!-- 自主标记确认弹窗 -->
    <s-lazy-mount>
      <ResumeShipmentDialog
        v-model:visible="showResumeShipmentDialog"
        :language="language"
        :async-callbacks="asyncCallbacks"
        :info="resumeShipmentInfo"
        :order-info="orderInfo"
      />
    </s-lazy-mount>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getRefundReasonListEvt } from 'public/src/pages/user/child_pages/orders/utils'
import { mapMutations, mapState } from 'vuex'
import schttp from 'public/src/services/schttp'
import orderLogic from 'public/src/pages/common/orderLogic'
import { htmlDecode } from '@shein/common-function'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { abtservice } from 'public/src/services/abt'
import refundReason from '../../../refund/refundReason.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { requestPageInitInfo } from 'public/src/pages/returnPayments/util'
import RefundAccount from '../../../refund/refund_account.vue'
import CommonTipsDialog from 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'
import ResumeShipmentDialog from 'public/src/pages/user/child_pages/orders/common/ResumeShipmentDialog.vue'
import { refundCountryPaymentCheck } from 'public/src/pages/user/child_pages/order_return/common/tools'
import CouponArticalPageTips from 'public/src/pages/user/child_pages/orders/common/couponArticalPageTips.vue'
import { adaModal, enterkey } from 'public/src/pages/common/ada/index.js'

daEventCenter.addSubscriber({ modulecode: '1-19-1' })
const {
  OLD_MAIN_ORDER,
  US_OLD_MAIN_NO_WITHDRAW,
  COUNTRY_PAYMENT_METHOD_CONFIG
} = typeof window != 'undefined' ? gbCommonInfo : {}
export default defineComponent({
  components: {
    SDialog,
    SButton,
    SButtonGroup,
    SButtonGroupItem,
    refundReason,
    RefundAccount,
    CommonTipsDialog,
    CouponArticalPageTips,
    SLazyMount,
    ResumeShipmentDialog,
  },
  directives: {
    adaModal,
    enterkey,
  },
  data() {
    return {
      newRefundReason: [],
      walletInfo: {},
      userCardInfo: {},
      giftCardInfo: {},
      activeSublist: -1,
      updateDefaultPath: false,
      refundDefaultPath: '',
      oldRefundMethodChecked: '',
      newRefundMethodChecked: '',
      isFirstUpdate: true,
      refundPathList: [
        {
          refundName: '',
          status: 0,
          value: 2,
          valid: [],
          invalid: []
        },
        {
          refundName: '',
          status: 0,
          value: 3,
          valid: [],
          invalid: []
        },
        {
          refundName: '',
          status: 0,
          value: 1,
          valid: [],
          invalid: []
        }
      ],
      showMinPrice: false,
      refundReasonDict: [],
      resultVisibleMap: {
        visible: false,
        message: '',
        method: null
      },
      couponArticalPage: true,
      showCouponArticalPageDig: false,
      changeTextType: -1,
      walletDefaultTips: false,
      apolloConfig: US_OLD_MAIN_NO_WITHDRAW || {},
      isFirstOpen: true,
      showTipsDialog: false,
      tipsText: '',
      apolloInfo: {},
      showGiftCardExpiredNotice: false,
      showRdfTaxesDialog: false,
      showAccountRequired: false,
      // 显示账号必填校验红色文案
      showAccountRequiredError: false,
      refundExpireState: 0,
      cancelShowAbt: false,
      asyncCallbacks: [],
      resumeShipmentInfo: {},
      showResumeShipmentDialog: false,
      outofStockShowAbt: false
    }
  },
  computed: {
    ...mapState('cancelItemModule', [
      'orderInfo',
      'language',
      'abtInfo',
      'refundForm',
      'refundAccountInfo',
      'isCheckAll',
    ]),
    isCod() {
      return this.orderInfo.payment_method == 'cod'
    },
    // 展示warm tips
    refundTipsDesc() {
      const { walletInfo = {}, userCardInfo = {}, giftCardInfo = {}, refundForm = {} } = this
      const refundMethod = Number(refundForm.refundMethod)
      const getText = (tips = []) => {
        if (!tips || !tips[0]) return ''
        return tips[0].text || ''
      }

      if (refundMethod === 2) {
        return getText(walletInfo.tips)
      }
      if (refundMethod === 3) {
        return getText(userCardInfo.tips)
      }
      if (refundMethod === 1) {
        return getText(giftCardInfo.tips)
      }
      return ''
    },
    // 显示账号编辑、新增按钮
    showAccountBtn() {
      return this.refundForm.refundMethod == 3 && this.refundAccountInfo.show
    },
    submitDisabled() {
      if ((!this.isCod && !this.refundForm.refundMethod) || !this.refundForm.reasonId) {
        return true
      }
      if (this.showAccountRequired) {
        // 如果开启了账号必填abt，非阿波罗配置的可维护账户国家直接禁用按钮
        return this.showAccountBtn && !this.isApolloCountryPayment
      }
      return (
        this.showAccountBtn &&
        !(this.refundAccountInfo.selected || this.isApolloCountryPayment)
      )
    },
    //是否满足阿波罗配置的国家和支付方式
    isApolloCountryPayment() {
      return orderLogic.isApolloCountryPayment({
        payment: this.orderInfo.payment_method,
        receiver_country: this.orderInfo.shipping_country_name
      })
    },
    isMatchCountryCond() {
      let { shipping_country_id = '' } = this.orderInfo || {}
      let { countryIdList = [] } = this.apolloConfig || {}
      return countryIdList?.includes(`${shipping_country_id}`)
    },
    isMatchPaymentMethodCond() {
      let { payment_method = '' } = this.orderInfo || {}
      let { paymentMethodList = [] } = this.apolloConfig || {}
      return paymentMethodList?.includes(`${payment_method}`)
    }
  },
  watch: {
    orderInfo: {
      immediate: true,
      handler() {
        this.init()
      }
    },
    abtInfo: {
      immediate: true,
      handler() {
        this.init()
      }
    },
    'refundForm.refundMethod': {
      handler(newValue) {
        if (newValue) {
          this.openGiftCardTips()
        }
      }
    }
  },
  async mounted() {
    await this.getAbtInfo()
    this.getApolloInfo()
    this.initRefundFormData()
    await this.fetchRefundReasonListEvt()
    this.initRefundReasonDict()
    if (this.refundForm.refundMethod) {
      this.openGiftCardTips()
    }
  },
  methods: {
    ...mapMutations('cancelItemModule', ['assignCancelItemState', 'updateRefundAccountInfo', 'changeRootStatus']),
    htmlDecode: text => htmlDecode({ text }),
    // 取消商品后展示订单标记问询
    cancelShowResumeShipment(res = {}) {
      const orderInfoList = res.info?.order_info_list || []
      const orderInfo = orderInfoList.find(i => i.billno === this.orderInfo.billno)
      const orderMarkList = orderInfo?.order_mark_list || []
      return (
        this.cancelShowAbt && 
        !this.isCheckAll && // 没有取消全部商品
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && i.trouble_reason == '3')
      )
    },
    // 等待用户选择自主标记的结果
    resumeShipmentSleep(info = {}) {
      return new Promise(resolve => {
        this.resumeShipmentInfo = info
        this.showResumeShipmentDialog = true
        this.asyncCallbacks = [resolve]
      })
    },
    onUpdateRefundAccountInfo(data) {
      this.updateRefundAccountInfo(data)
      if (this.refundAccountInfo.selected) {
        this.showAccountRequiredError = false
      }
    },
    async getAbtInfo() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ 
        posKeys: 'couponarticalpage',
        newPosKeys: 'gifcardExpirednotice,accountRequired,markOrder' 
      })
      const accountParam = abtInfo?.['accountRequired']?.param?.accountRequired || ''
      this.couponArticalPage = abtInfo?.['couponarticalpage']?.param != 'hide'
      this.showGiftCardExpiredNotice = abtInfo?.['gifcardExpirednotice']?.param?.gifcardExpirednotice == 'on'
      // 发货前整单退/部分退校验打款账号必填
      this.showAccountRequired = accountParam.indexOf('on_processing') > -1
      this.cancelShowAbt = abtInfo?.markOrder?.param?.cancel_show == 'on'
      this.outofStockShowAbt = abtInfo?.markOrder?.param?.outofStock_show == 'on'
    },
    // 获取页面apollo
    async getApolloInfo() {
      const apolloKeys = [
        'GIFCARD_EXPIRED_CONFIG',
      ]
      const res = await schttp({
        method: 'POST',
        url: '/api/config/apolloConfig/query',
        data: { apolloKeys: apolloKeys.join(',') }
      })
      if (res?.code == 0) {
        this.apolloInfo = res.info || {}
      }
    },
    exposeGiftCardNotice(params = {}) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-141',
        extraData: params,
      })
    },
    openGiftCardTips() {
      this.tipsText = ''
      const { card_no, exp_time } = this.refundForm.data || {}
      if (
        !this.showGiftCardExpiredNotice ||
        !this.isFirstOpen ||
        !card_no ||
        !exp_time
      ) {
        return
      }
      const currentTime = Date.now()
      const expiredTime = exp_time * 1000
      const timeDifference = expiredTime - currentTime
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
      // 礼品卡失效
      if (daysDifference <= 0) {
        if (!this.walletDefaultTips && !this.updateDefaultPath) {
          this.showTipsDialog = true
        }
        this.isFirstOpen = false
        this.tipsText = this.language.SHEIN_KEY_PWA_30902
        this.exposeGiftCardNotice({ notice_type: 1 })
        return 
      }
      const { GIFCARD_EXPIRED_CONFIG = {} } = this.apolloInfo || {}
      const { shipping_country_id = '' } = this.orderInfo || {}
      let giftCardConfigList = GIFCARD_EXPIRED_CONFIG?.[shipping_country_id]
      if (!Array.isArray(giftCardConfigList)) {
        giftCardConfigList = GIFCARD_EXPIRED_CONFIG?.default || []
      }
      if (Array.isArray(giftCardConfigList) && giftCardConfigList.length) {
        const giftItem = giftCardConfigList.find(item => item.status === 'process')
        // 如果差值≤阿波罗配置中“对应国家ID且为PROCESS”所配置的天数，视为礼品卡临期
        if (giftItem && daysDifference <= giftItem.value) {
          if (!this.walletDefaultTips && !this.updateDefaultPath) {
            this.showTipsDialog = true
          }
          this.isFirstOpen = false
          this.tipsText = this.language.SHEIN_KEY_PWA_30903
          this.exposeGiftCardNotice({
            giftcard_aging: Math.ceil(timeDifference / 1000),
            notice_type: 2
          })
        }
      }
    },
    async fetchRefundReasonListEvt() {
      // 获取退款原因 非cod支付后 分类code为1
      const paytime = this.refundForm?.data?.related_orders?.map((i)=>{
        if (+i?.pay_time > 0) return +i?.pay_time
      })?.sort((a, b)=>a - b)
      let { business_model, shipping_country_id } = this.orderInfo || {}
      const returnReasonList = await getRefundReasonListEvt({
        first_reason_code: this.isCod ? 2 : 1,
        business_model,
        shipping_country_id,
        payTime: paytime?.length > 0 ?  paytime[paytime.length - 1] : ''
      })
      this.setReturnReasonList(returnReasonList)
    },
    init() {
      this.handleUpdateRefundForm({
        reason: this.language.SHEIN_KEY_PWA_15434
      })
    },
    async initRefundFormData() {
      let {
        omsData: { price = {} } = {},
        refund_path_tip = {},
        company_id
      } = this.refundForm?.data || {}
      let {
        giftcard_refundable = {},
        usercard_refundable = {},
        wallet_refundable = {},
        total_refund = {},
        company_not_refund_wallet_reason
      } = price || {}
      let totalRefundUsd = total_refund && Number(total_refund.usd || 0)
      let walletUsd = wallet_refundable && Number(wallet_refundable.usd || 0)
      let giftcardUsd = giftcard_refundable && Number(giftcard_refundable.usd || 0)
      let usercardUsd = usercard_refundable && Number(usercard_refundable.usd || 0)
      let overTwoWay = [walletUsd, giftcardUsd, usercardUsd].filter(usd => !!usd)
      this.walletInfo = (refund_path_tip && refund_path_tip.wallet) || {}
      this.userCardInfo = (refund_path_tip && refund_path_tip.user_card) || {}
      this.giftCardInfo = (refund_path_tip && refund_path_tip.gift_card) || {}
      const infos = [
        { ...this.giftCardInfo, refundNameLang: this.language.SHEIN_KEY_PWA_15846 },
        { ...this.walletInfo, refundNameLang: this.language.SHEIN_KEY_PWA_16402 },
        { ...this.userCardInfo, refundNameLang: this.language.SHEIN_KEY_PWA_15432 }
      ]
      this.refundPathList.forEach(item => {
        const itemInfo = infos[item.value - 1]
        if (itemInfo) {
          item.status = itemInfo.status
          item.valid = itemInfo.valid || []
          item.invalid = itemInfo.invalid || []
          item.refundName = itemInfo.refundNameLang
        }
        if (
          totalRefundUsd > 0 &&
          ((walletUsd <= 0 && giftcardUsd <= 0) || overTwoWay.length >= 2)
        ) {
          item.isHideMethod = true
        }
      })
      const { company_can_refund_wallet } = price || {}
      if (walletUsd > 0) {
        this.getRefundOrderExpireAmountStatus()
      }
      if (company_can_refund_wallet == 2 || this.isApolloCountryPayment ) {
        const isDisplayRefundAccount = await this.detecRefundAccountIsDisplay()
        if (isDisplayRefundAccount) {
          // 巴西支持打款且钱包字段company_can_refund_wallet=1增加钱包
          let newWalletFrom
          if (company_can_refund_wallet == 1) {
            newWalletFrom = this.refundPathList.find(item => item.value == 2)
          }

          this.refundPathList = [
            {
              refundName:
                this.orderInfo.shipping_country_name == 'Brazil'
                  ? this.language.SHEIN_KEY_PWA_26117
                  : this.language.SHEIN_KEY_PWA_19492,
              status: this.userCardInfo.status,
              value: 3, // 账户
              valid: [],
              invalid: []
            }
          ]

          if (newWalletFrom) this.refundPathList.push(newWalletFrom)
          this.updateRefundAccountInfo({ show: true })
        }
      }

      // 默认选中问题
      if (this.walletInfo.is_default_path == 1 && this.walletInfo.status == 1) {
        this.refundDefaultPath = 2
      } else if (this.userCardInfo.is_default_path == 1 && this.userCardInfo.status == 1) {
        this.refundDefaultPath = 3
      } else if (this.giftCardInfo.is_default_path == 1 && this.giftCardInfo.status == 1) {
        this.refundDefaultPath = 1
      } else {
        this.refundDefaultPath = ''
      }
      // 有几种退款方式
      // 根据默认路径来显示排序
      let refundWayList = this.refundPathList.map(item => item.value)
      let unableList = this.refundPathList.filter(item => !item.status)
      let ableList = this.refundPathList.filter(item => item.status == 1)
      ableList = ableList.map(item => {
        const valid = item.valid || []
        return {
          ...item,
          valid: valid.map(validItem => {
            return {
              ...validItem,
              text: this.formatValidText({ text: validItem.text, id: company_id })
            }
          })
        }
      })
      this.refundPathList = this.setSortList(ableList).concat(this.setSortList(unableList))
      // 旧主体置换钱包跟账户的状态
      this.oldMainOrderEvt(price)
      if (this.refundDefaultPath) {
        this.refundDefaultPath = refundWayList.includes(Number(this.refundDefaultPath))
          ? this.refundDefaultPath
          : ''
        this.refundPathList.sort(item => (item.value == this.refundDefaultPath ? -1 : 1))
      }
      if (!this.userCardInfo.status && this.refundForm.refundMethod == 3) {
        this.handleUpdateRefundForm({
          refundMethod: ''
        })
      } else {
        this.handleUpdateRefundForm({
          refundMethod: this.refundDefaultPath || ''
        })
        if (this.refundDefaultPath == 2 && this.isMatchCountryCond && !this.isCod) {
          this.walletDefaultTips = true
        }
      }
      // 判断是否命中高风险
      if (company_not_refund_wallet_reason == 3) {
        if (this.refundDefaultPath == 2) {
          this.rmcontent = ''
        }
        this.refundPathList = this.refundPathList.filter(item=>item.value !== 2)
      }
    },
    formatValidText({ id = '', text = '' } = {}) {
      if (!text) {
        return text
      }
      const isRuMainPart =
        ['Russian Federation', 'Belarus'].includes(this.orderInfo.shipping_country_name) &&
        id !== 13
      return text.replace(/\{\$(.+)\$\}/, isRuMainPart ? '$1' : '')
    },
    async getRefundOrderExpireAmountStatus(){
      const handle =  this.refundForm?.data?.refund_goods?.map(i=>i?.billno)
      if(!handle?.length) return
      const { data = {} } = await schttp({
        method: 'POST',
        url: '/api/user/wallet/RefundOrderExpireAmountStatus/get',
        data: { 
          bill_no_list: [...new Set(handle)],
          refund_amount: this.refundForm?.data?.omsData?.price?.total_refund_price?.amount
        },
      })

      if(data?.code == '0') {
        this.refundExpireState =  data?.info?.expire_amount_status
      }
    },
    oldMainOrderEvt(price = {}) {
      let { company_can_refund_wallet } = price || {}
      if (!(company_can_refund_wallet == 2 && OLD_MAIN_ORDER == 1)) return
      let { status: walletStatus } = this.refundPathList.find(item => item.value == 2) || {}
      let { status: userStatus } = this.refundPathList.find(item => item.value == 3) || {}
      if (walletStatus == 1 && userStatus != 1) {
        this.refundPathList.forEach(item => {
          if (item.value == 2) {
            item.status = 0
            // 如果钱包是默认退款方式, 则去掉默认
            this.refundDefaultPath =
              item.value == this.refundDefaultPath ? '' : this.refundDefaultPath
          } else if (item.value == 3) {
            item.status = 1
          }
        })
      }
    },
    initRefundReasonDict() {
      const { newRefundReason = [], language = {} } = this
      // 展示疫情相关
      const reasonMap = [
        {
          index: 2,
          val: language.SHEIN_KEY_PWA_15443,
          valEN: language.lang_member_notcod_reason_en_1
        },
        {
          index: 5,
          val: language.SHEIN_KEY_PWA_15444,
          valEN: language.lang_member_notcod_reason_en_2
        },
        {
          index: 75,
          val: language.SHEIN_KEY_PWA_15446,
          valEN: language.lang_member_notcod_reason_en_4
        },
        {
          index: 76,
          val: language.SHEIN_KEY_PWA_15447,
          valEN: language.lang_member_notcod_reason_en_5
        },
        {
          index: 77,
          val: language.SHEIN_KEY_PWA_15448,
          valEN: language.lang_member_notcod_reason_en_6
        }
      ]

      reasonMap.push({
        index: -1,
        val: language.SHEIN_KEY_PWA_15188,
        valEN: 'Others',
        sublist: [
          { index: 118, val: language.SHEIN_KEY_PWA_17045, valEN: 'Coronavirus-related' },
          { index: 119, val: language.SHEIN_KEY_PWA_17046, valEN: 'Not Coronavirus-related' }
        ]
      })

      this.refundReasonDict = newRefundReason.length ? newRefundReason : reasonMap
    },
    setSortList(list = []) {
      if (!list.length) return list
      let wallet = list.filter(item => item.value == 2)
      let userCard = list.filter(item => item.value == 3)
      let giftCard = list.filter(item => item.value == 1)
      return [...wallet, ...userCard, ...giftCard]
    },
    setReturnReasonList(returnReasonList) {
      if (returnReasonList.length) {
        // 判断其它的情况下是否有疫情原因
        const reasonCodeList = returnReasonList.map(codeItem => codeItem.reasonCode)
        const isHasEpidemic =
          reasonCodeList.includes(-1) &&
          (reasonCodeList.includes(118) || reasonCodeList.includes(119))
        if (isHasEpidemic) {
          const tempList = []
          const secondReasonList = returnReasonList.filter(reasonList =>
            [118, 119].includes(reasonList.reasonCode)
          )
          const firstReasonList = returnReasonList.filter(
            reasonList => ![118, 119].includes(reasonList.reasonCode)
          )
          firstReasonList.forEach(reasonList => {
            if (![-1].includes(reasonList.reasonCode)) {
              tempList.push({
                index: reasonList.reasonCode,
                val: reasonList.languageCopy,
                valEN: reasonList.languageCopy
              })
            } else {
              const tempSecondReasonList = []
              secondReasonList.forEach(secondReasonList => {
                tempSecondReasonList.push({
                  index: secondReasonList.reasonCode,
                  val: secondReasonList.languageCopy,
                  valEN: secondReasonList.languageCopy
                })
              })
              tempList.push({
                index: reasonList.reasonCode,
                val: reasonList.languageCopy,
                valEN: reasonList.languageCopy,
                sublist: tempSecondReasonList
              })
            }
          })
          this.newRefundReason = tempList
        } else {
          const tempList = []
          returnReasonList.forEach(reasonList => {
            tempList.push({
              index: reasonList.reasonCode,
              val: reasonList.languageCopy,
              valEN: reasonList.languageCopy
            })
          })
          this.newRefundReason = tempList
        }
      } else {
        this.newRefundReason = []
      }
    },
    showRefundReason() {
      this.$refs['refundReasonMask'].show = !this.$refs['refundReasonMask'].show
    },
    selectRefoundReason(isCommonReason, reason = {}) {
      const { val, index } = reason
      if (isCommonReason) this.activeSublist = -1
      this.handleUpdateRefundForm({
        reason: val,
        reasonId: index,
        noreason: false
      })
    },
    handleHideResultDialog() {
      const { method } = this.resultVisibleMap
      this.resultVisibleMap.visible = false
      this.resultVisibleMap.message = ''
      if (typeof method === 'function') {
        method()
        this.resultVisibleMap.method = null
      }
      if (typeof window !== 'undefined') {
        location.reload()
      }
    },
    handleShowResultDialog(options) {
      this.resultVisibleMap = {
        ...this.resultVisibleMap,
        ...options,
        visible: true
      }
    },
    // 返回
    handleRefundHidden() {
      this.showMinPrice = false
      this.handleUpdateRefundForm({
        visibleMainDrawer: false
      })
    },
    async handleRefundSubmit() {
      // 是否显示账号必填错误提示
      if (this.showAccountRequired && this.showAccountBtn && !this.refundAccountInfo.selected) {
        this.showAccountRequiredError = true
        SToast(this.language.SHEIN_KEY_PWA_31943)
        return
      }
      this.showAccountRequiredError = false
      let status = false
      let language = this.language
      let { refundMethod: refund_path = '', reasonId: cancel_reason = '' } = this.refundForm || {}
      if (!cancel_reason) {
        this.handleUpdateRefundForm({ noreason: true })
        status = true
      }
      if (status || (!this.isCod && refund_path == '')) {
        return
      }
      this.changeRootStatus({
        loading: true
      })
      let params = this.getSubmitParamsEvt(refund_path, cancel_reason)
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getUnionRevokeRefund/query',
        data: { list: JSON.stringify(params) },
        headers: { blackbox: window?._fmOpt?.__blackbox || '' }
      })
      this.isCod && this.submit(res.code == 300321 ? 2 : 1)
      this.changeRootStatus({
        loading: false
      })
      let { billno = '' } = this.orderInfo || {}
      let hasOwnOrderGoods = res?.info?.success_list?.filter(item => item.billno == billno)
      if (res.code == '0' && hasOwnOrderGoods?.length) {
        const currentOrderInfo = res?.info?.order_info_list?.find(item => item?.billno == billno) || {}
        if (this.cancelShowResumeShipment(res)) {
          await this.resumeShipmentSleep({ scene: 'cancel' })
        } else if (this.cancleOutOfStockResumeShipment(currentOrderInfo)) {
          await this.resumeShipmentSleep({
            scene: 'outofstock'
          })
        }
        // 取消订单需要触发homeCccxCouponUpdate事件，首页监听这个事件去更新劵包信息
        appEventCenter.emit('homeCccxCouponUpdate')
        if (this.isCod) {
          this.assignCancelItemState({
            matchCompName: 'cod-result',
            compStacks: []
          })
          return
        }
        !this.isCod && this.submitEvt('1')
        sa('send', { activity_name: 'expose_popup_unshipped_cancel_item_success' })
        this.handleShowResultDialog({
          message: language.SHEIN_KEY_PWA_21356,
          method: () => {
            sa('send', { activity_name: 'click_unshipped_cancel_item_success_vieworders' })
          }
        })
      } else {
        if ([300321].includes(+res.code)) {
          this.showMinPrice = true
          return
        }
        let hasOwnOrderMsg = res?.info?.fail_list?.find(item => item.billno == billno) || {}
        let textMap = {
          300003: hasOwnOrderMsg?.tips || language.SHEIN_KEY_PWA_17318,
          666666: language.SHEIN_KEY_PWA_17115,
          777777: language.SHEIN_KEY_PWA_17115,
          210005: language?.SHEIN_KEY_PWA_37423 || '',
        }
        !this.isCod && this.submitEvt('0')
        this.handleShowResultDialog({
          message: textMap[+res.code] || hasOwnOrderMsg?.tips || language.SHEIN_KEY_PWA_21354
        })
      }
    },
    getSubmitParamsEvt(refund_path, cancel_reason) {
      let { billno = '', orderGoodsList = [] } = this.orderInfo || {}
      let { data = {} } = this.refundForm || {}
      let { refund_goods = [] } = data || {}
      let tempRefundList = refund_goods?.length ? refund_goods : orderGoodsList?.flat(Infinity)
      let curServerType = orderLogic.getRefundSpecialReason()
      let list = []
      let groupByBillno = {}
      tempRefundList?.forEach(item => {
        let { billno: curBillno = billno, order_goods_id, id, is_added_order } = item
        if (groupByBillno[curBillno]) {
          groupByBillno[curBillno] = {
            curBillno,
            is_added_order,
            goods_ids: [...groupByBillno[curBillno].goods_ids, order_goods_id || id]
          }
        } else {
          groupByBillno[curBillno] = {
            curBillno,
            is_added_order,
            goods_ids: [order_goods_id || id]
          }
        }
      })
      let groupBillnoValues = Object.values(groupByBillno) || []
      groupBillnoValues?.forEach(item => {
        let { curBillno, goods_ids, is_added_order } = item
        list.push({
          billno: curBillno,
          order_goods_ids: goods_ids,
          refund_path: this.isCod ? 1 : refund_path,
          is_current: curBillno == billno ? 1 : 0,
          cancel_reason: is_added_order == 1 ? curServerType : cancel_reason,
          reason: is_added_order == 1 ? curServerType : cancel_reason
        })
      })
      return list
    },
    /**
     * @description 更新refundForm
     * @date 2021-09-28 14:09
     * @param {object} info
     */
    handleUpdateRefundForm(info = {}) {
      this.assignCancelItemState({
        refundForm: {
          ...(this.refundForm || {}),
          ...info
        }
      })
    },
    submitEvt(value) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-48',
        extraData: {
          value: value
        }
      })
    },
    submit: function (cod_result) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-45',
        extraData: {
          billno: this.orderInfo.billno || '',
          order_type: this.isCod ? 1 : 2,
          cod_result: cod_result
        }
      })
    },
    changeMethod(type, status) {
      if (!status) return
      if (this.isFirstUpdate && this.refundDefaultPath == type) return
      if (this.refundDefaultPath && this.isFirstUpdate) {
        this.changeTextType = type == 2 && this.isMatchCountryCond && !this.isCod ? 2 : 1
        this.handleUpdateRefundForm({ refundMethod: this.refundDefaultPath })
        this.oldRefundMethodChecked = this.refundDefaultPath
        this.newRefundMethodChecked = type
        this.updateDefaultPath = !!(
          (type == 2 && this.isMatchCountryCond && !this.isCod) ||
          this.isFirstUpdate
        )
        this.updateDefaultPath && this.openGiftCardTips()
        return
      }
      if (type == 2 && this.isMatchCountryCond) {
        this.changeTextType = 2
        this.handleUpdateRefundForm({ refundMethod: this.oldRefundMethodChecked })
        this.newRefundMethodChecked = type
        this.updateDefaultPath = true
        this.openGiftCardTips()
        return
      }
      this.newRefundMethodChecked = type
      this.oldRefundMethodChecked = type
      this.handleUpdateRefundForm({ refundMethod: type })
    },
    cancelUpdateDefaultPath() {
      this.handleUpdateRefundForm({ refundMethod: this.oldRefundMethodChecked })
      this.updateDefaultPath = false
    },
    confirmUpdateDefaultPath() {
      this.updateDefaultPath = false
      this.isFirstUpdate = false
      this.oldRefundMethodChecked = this.newRefundMethodChecked
      this.handleUpdateRefundForm({ refundMethod: this.newRefundMethodChecked })
    },
    // 判断是否展示自主账号填写
    async detecRefundAccountIsDisplay() {
      // return true
      const { payment_method, shipping_country_name } = this.orderInfo
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ posKeys: 'CODwithdraw' })
      const params = abtInfo['CODwithdraw'].param || ''
      if (params.indexOf('return=ON') == -1) return false

      // 判断返回可修改标识 || 接口失败 || 没有已保存打款账户
      await this.getRefundAccountInfo()
      let isPass = refundCountryPaymentCheck({ status: this.userCardInfo.status, COUNTRY_PAYMENT_METHOD_CONFIG, receiver_country: shipping_country_name, payment: payment_method })
      // 只有特定的支付方式和运输地区才继续往下判断
      if (isPass) return true

      return false
    },
    async getRefundAccountInfo() {
      const { code, account } = await requestPageInitInfo({
        shippingCountryId: this.orderInfo.shipping_country_id,
        payMethod: this.orderInfo.payment_method
      })
      if (code == 0) {
        const accountInfo = (account.info && account.info.list && account.info.list[0]) || {}
        const acc = accountInfo.accountDisplay || ''
        const optionalPaymentMethod = account?.info?.optionalPaymentMethod || []
        this.updateRefundAccountInfo({ account: acc, info: accountInfo, selected: acc, optionalPaymentMethod  })
      }
    },
    cancleOutOfStockResumeShipment(currentOrderInfo = {}) {
      const {
        order_mark_list: orderMarkList = [],
        order_goods_list: orderGoodsList = []
      } = currentOrderInfo
      const currentCancelId = this.refundForm?.data?.refund_goods?.[0]?.order_goods_id
      const isGoodsMatch = orderGoodsList.some(i => {
        return ![0, 16, 5, 12, 13, 7, 14, 15, 4].includes(i.order_goods_status)
         && i.id != currentCancelId
      })
      return (
        this.outofStockShowAbt && 
        isGoodsMatch &&
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && i.trouble_reason == '298')
      )
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-refund-fr {
  .fr();
}

.order-refund-fl {
  .fl();
}
.refund-item2 {
  .orh-title {
    border-bottom-width: 1px;
  }
  .refund-body {
    > ul > li {
      padding: 24/75rem;
      border-bottom: 0.26rem solid #fafafa;
      &.refund-method {
        .method {
          display: inline-block;
          min-width: 2.2rem;
          padding: 0.2rem;
          text-align: center;
          border: 1px solid #ccc;
          .margin-r(0.5rem);

          &.method-select {
            border-color: #333;
          }
          i[class*='iconfont'] {
            .font-dpr(32px);
          }
        }
      }
    }
    .refund-amount {
      overflow: hidden;
      font-weight: 600;
      .font-dpr(28px);
      background: #fff;
      margin-top: 0.32rem;
      .total-container {
        height: 48/75rem;
      }
    }
    .refund-tips {
      background: #f6f6f6;
      padding: 0.5rem 0.25rem 1rem;
      color: #999;
      .font-dpr(24px);
      &__title {
        margin-bottom: 0.27rem;
      }
    }
    .refund-btn {
      padding: 0 24/75rem;
      background: #fafafa;
      height: 6rem;
      button {
        width: 100%;
      }
    }
    .refund-reason {
      background: #fff;
      margin: 0;
      padding: 24/75rem;
      .reason {
        overflow: hidden;
        padding: 0.3rem;
        border-top: 1px solid #e5e5e5;
        margin: 0 -0.3rem;
        .font-dpr(28px);
        label {
          margin: 0;
          height: 20px;
        }
      }
      .reason-ctn {
        position: relative;
        > i {
          position: absolute;
          top: 50%;
          // right: .2rem;
          .right(0.2rem);
          transform: translateY(-50%);
        }
      }
      .select-list {
        border: none;
        padding: 0;
      }
    }
    .refund-hint {
      color: #A86104;
      .font-dpr(24px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .refund-alert {
      width: 100%;
      padding: 12/75rem 16/75rem;
      position: relative;
      margin-bottom: 16/75rem;
      &::after{
        content: '';
        position: absolute;
        top: -28/75rem;
        right: 40/75rem;
        border: 18/75rem solid transparent;
        border-bottom-color: #fff1d5;
        width: 18/75rem;
        height: 18/75rem;
        display: inline-block;
      }
      .hint-container {
        display: flex;
        align-items: center;
      }
      .expire-text {
        padding-left: 8/75rem;
        color:#000;
        .font-dpr(24px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.wallet-card {
  background: #fff;
  box-sizing: border-box;
  padding: 0 0.4rem;
  .title {
    .font-dpr(24px);
    font-weight: bold;
    color: #333;
  }
  .reason-list {
    .font-dpr(28px);
    color: #222;
    overflow: hidden;
  }
  .reason {
    height: 1rem;
    line-height: 1rem;
    .font-dpr(26px);
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .wallet-card-item {
    box-sizing: border-box;
    margin: 0 0.2rem;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    &.method-not {
      opacity: 0.4;
    }
    .wallet,
    .card {
      padding: 0.2rem 0;
      .font-dpr(26px);
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      .flexbox();
      align-items: center;
      justify-content: space-between;
    }
    .checkbox {
      width: 6%;
      position: relative;
      .wallet-radio {
        height: 0.5rem;
        width: 0.5rem;
        display: inline-block;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #222;
        position: relative;
        text-align: center;
        line-height: 0.5rem;
        & when(@IS_RW) {
          border: 1px solid #ff696e;
        }
        i {
          display: inline-block;
          color: #fff;
        }
      }
      .check-all {
        box-sizing: border-box;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px solid #999;
      }
      .check-all-had {
        background: url('/pwa_dist/images/user/check-36c3d0a1de.png') no-repeat;
        background-size: cover;
        background-origin: border-box;
        border: none;
      }
      &.disabled {
        .wallet-radio {
          border: 1px solid #ccc;
        }
      }
      &.checked {
        .wallet-radio {
          background: #222;
          & when(@IS_RW) {
            background: #ff696e;
          }
        }
      }
    }
    .method-item {
      position: relative;
      display: inline-block;
      width: 80%;
      text-indent: 0.8rem;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        .left(0);
        content: '';
        overflow: hidden;
        width: 0.5333rem;
        height: 0.5333rem;
      }
    }
    .wallet-style {
      &:before {
        background: url('/pwa_dist/images/user/wallet-973f8c4087.png') no-repeat;
        background-size: cover;
      }
    }
    .account-style {
      &:before {
        background: url('/pwa_dist/images/user/paymentAccount-875962130b.png') no-repeat;
        background-size: cover;
      }
    }
    .gift-style {
      &:before {
        background: url('/pwa_dist/images/user/gift-c4db0de4ef.png') no-repeat;
        background-size: cover;
      }
    }
    .des {
      box-sizing: border-box;
      padding: 0 0 0.266rem 0.8rem;
      .font-dpr(24px);
      font-weight: 400;
      color: rgba(155, 155, 155, 1);
      line-height: 1.25em;
      overflow: hidden;
    }
  }
  .wallet-card-item:last-child {
    border: none;
  }
}
.refund-item-wrap {
  background-color: #fafafa;
  flex: 1;
  .refundCalMask {
    font-weight: normal;
  }
}
.gift-tips-text {
  margin-top: 16/75rem;
}
</style>
