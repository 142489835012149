<template>
  <div class="cod-result-wrap">
    <div class="result-list-box">
      <div class="list-icon">
        <i class="iconfont icon-success"></i>
      </div>
      <div class="list-tips">
        <div
          class="list-tips-title"
          v-html="htmlDecode({ text: language.SHEIN_KEY_PWA_21356, isHtml: true })"
        >
        </div>
      </div>
      <div class="list-btn">
        <button
          class="code-res-btn-black"
          @click="resultOperatorEvt"
        >
          {{ language.SHEIN_KEY_PWA_16832 }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { htmlDecode } from '@shein/common-function'
const { langPath = '' } = typeof window != 'undefined' ? gbCommonInfo : {}

daEventCenter.addSubscriber({ modulecode: '1-19-1' })
export default defineComponent({
  data() {
    return {}
  },
  computed: {
    ...mapState('cancelItemModule', [
      'orderInfo',
      'language',
      'visibleMainDrawer',
      'matchCompName'
    ]),
    isCod() {
      return this.orderInfo?.payment_method === 'cod'
    }
  },
  methods: {
    htmlDecode,
    resultOperatorEvt() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-46',
        extraData: {
          billno: this.orderInfo.billno || '',
          order_type: this.isCod ? 1 : 2
        }
      })
      if (typeof window !== 'undefined') {
        location.href = langPath + '/user/orders/detail/' + this.orderInfo.billno
      }
    }
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.code-res-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: @color_brand;
  .border-dpr(border, 2px, transparent);
  padding: 0 0.4rem;
  color: #fff;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.result-list-box {
  background: #fff;
  padding: 65/75rem 24/75rem 0 24/75rem;
  .list-icon {
    text-align: center;
    i {
      .font-dpr(140px);
    }
  }
  .list-tips {
    margin-top: 25/75rem;
    text-align: center;
    .list-tips-title {
      .font-dpr(36px);
      color: #333;
    }
    .list-tips-ctn {
      margin-top: 12/75rem;
      color: #666;
    }
  }
  .list-btn {
    margin-top: 30/75rem;
    text-align: center;
    button {
      width: 100%;
    }
  }
}
.cod-result-wrap {
  flex: 1;
}
</style>
