<template>
  <div class="item-list-wrap">
    <div class="item-list-box">
      <div
        v-for="(item, index) in checkListCanCheck"
        :key="item.id"
        tabindex="0"
        class="item-list"
      >
        <div
          v-if="isCod ? isDisableCodCheck(item) :isDisabledChecked"
          class="obscuration"
        >
        </div>
        <div
          class="check-box"
          @click="changeCheck(index, item)"
        >
          <s-checkbox
            class="check-box__input"
            name="goodsbox"
            :model-value="item.checked"
            :disabled="isCod ? isDisableCodCheck(item) :isDisabledChecked"
          />
        </div>
        <div class="item-img-box">
          <div class="item-a">
            <!-- <img
              class="item-img"
              :src="transformImg({ img: item.product.goods_img })"
              :alt="language.SHEIN_KEY_PWA_14974"
            /> -->
            <CropImageContainer
              :img-src="transformImg({ img: item.product.goods_img })"
              fixed-ratio="3-4"
            />
          </div>
        </div>
        <div class="des-box">
          <p class="g-name">
            <a
              tabindex="-1"
              :href="
                langPath +
                  getGoodsUrl(item.product.goods_url_name, item.product.goods_id, item.product.cat_id)
              "
            >
              {{ item.product.goods_name ? item.product.goods_name : '' }}
            </a>
          </p>
          <div class="size-price">
            <p class="g-size">
              <skuSizeCommon
                :goods-info="item"
                :only-show-goods-attr="true"
              />
            </p>
            <p class="g-price">
              {{ item.avgPrice.amountWithSymbol }}
            </p>
          </div>
          <p class="g-num">
            x1
          </p>
          <p class="g-item-status">
            {{ getStatusText(item) }}
          </p>
        </div>
      </div>
      <div
        v-for="(item, index) in checkListCannotCheck"
        :key="index"
        tabindex="0"
        class="item-no-list"
      >
        <div class="item-list__content">
          <div class="obscuration"></div>
          <div class="check-box">
            <div class="check-all"></div>
          </div>
          <div class="item-img-box">
            <div class="item-a">
              <!-- <img
              class="item-img"
              :src="transformImg({ img: item.product.goods_img })"
              :alt="language.SHEIN_KEY_PWA_14974"
            /> -->
              <CropImageContainer
                :img-src="transformImg({ img: item.product.goods_img })"
                fixed-ratio="3-4"
              />
            </div>
          </div>
          <div class="des-box">
            <p class="g-name">
              <a
                tabindex="-1"
                :href="
                  langPath +
                    getGoodsUrl(
                      item.product.goods_url_name,
                      item.product.goods_id,
                      item.product.cat_id
                    )
                "
              >
                {{ item.product.goods_name ? item.product.goods_name : '' }}
              </a>
            </p>
            <div class="size-price">
              <p class="g-size">
                <skuSizeCommon
                  :goods-info="item"
                  :only-show-goods-attr="true"
                />
              </p>
              <p class="g-price">
                {{ item.avgPrice.amountWithSymbol }}
              </p>
            </div>
            <p class="g-num">
              x1
            </p>
            <p class="g-item-status">
              {{ getStatusText(item) }}
            </p>
          </div>
        </div>
        <div
          v-if="isShowCustomizationText(item)"
          class="refund-item__customization"
        >
          <TipsTextEllipsis :language="language">
            <template #default="{ className }">
              <span
                class="time-text"
                :class="className"
              >
                <span>{{ language.SHEIN_KEY_PWA_32166 }}</span>
              </span>
            </template>
          </TipsTextEllipsis>
        </div>
      </div>
    </div>
    <div
      ref="cancelItemAllBox"
      class="cancel-item-all__box"
    >
      <div
        v-if="showBoxTips"
        class="tips-ctx"
      >
        {{ htmlDecode(language.SHEIN_KEY_PWA_18530.replace('{0}', minPriceObj.amountWithSymbol)) }}
      </div>
      <div class="selected-ctx">
        <div
          class="checkout-box"
          @click="changeCheckAll"
        >
          <s-popover
            v-model="showPopover"
            trigger="user"
            :append-to-body="false"
            :show-close-icon="true"
            :prop-style="popoverStyle"
            placemen="top-start"
            :content="language.SHEIN_KEY_PWA_32167"
            @icon-close="popoverClose"
          >
            <template #reference>
              <s-checkbox
                class="check-box__input"
                name="goodsbox"
                :model-value="checkedAll"
                :disabled="isDisabledChecked"
                gap="0.2rem"
              />
            </template>
          </s-popover>
          {{ htmlDecode(language.SHEIN_KEY_PWA_16073) }}
        </div>
        <button
          class="order-item-list-btn-black order-item-list-btn-xs check-all-btn"
          :disabled="isDisabledBtn"
          @click="handleCancelItem"
        >
          {{ htmlDecode(language.SHEIN_KEY_PWA_15884) }}
        </button>
      </div>
    </div>
    <!-- 数量过多提示 -->
    <s-dialog
      v-model:visible="exceededQuantityVisible"
      :append-to-body="true"
    >
      <p>{{ htmlDecode(language.SHEIN_KEY_PWA_16074) }}</p>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          width="100%"
          @click="exceededQuantityVisible = false"
        >
          {{ language.SHEIN_KEY_PWA_15146 }}
        </s-button>
      </template>
    </s-dialog>
    <!--status change-->
    <s-dialog
      v-model:visible="statusChange"
      :append-to-body="true"
    >
      <p>{{ htmlDecode(language.SHEIN_KEY_PWA_16075) }}</p>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          width="100%"
          @click="closeCancelItemDrawer"
        >
          {{ language.SHEIN_KEY_PWA_15146 }}
        </s-button>
      </template>
    </s-dialog>
    <cancelRelationGoodItem
      :show="showRelationDialog"
      :relationGoodsList="relationGoodsList"
      :relationOptions="relationOptions"
      :language="language"
      :exposeCombinedRefundTip="exposeCombinedRefundTip"
      @confirmCancelOrder="confirmCancelOrder"
      @updateShowValue="updateShowValue"
    />
    <!-- 退款金额确认弹窗 -->
    <SLazyMount>
      <RefundAmountDrawer
        v-model:visible="showRefundAmountDrawer"
        :language="language"
        :order-currency="orderCurrency"
        :refund-amount-info="refundAmountObj"
        :async-callbacks="asyncCallbacks"
      />
    </SLazyMount>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import cancelRelationGoodItem from 'public/src/pages/user/child_pages/orders/list/cancelRelationGoodItem.vue'
import { htmlDecode, transformImg, replaceHrefSpecChar } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import { mapMutations, mapState } from 'vuex'
import { sumBy } from 'lodash'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import orderLogic from 'public/src/pages/common/orderLogic'
import skuSizeCommon from 'public/src/pages/common/orderLogic/skuSizeCommon.vue'
import RefundAmountDrawer from 'public/src/pages/user/child_pages/order_return/returns/components/RefundAmountDrawer.vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import schttp from 'public/src/services/schttp'
import TipsTextEllipsis from './TipsTextEllipsis.vue'

daEventCenter.addSubscriber({ modulecode: '1-19-1' })
export default defineComponent({
  components: {
    SDialog,
    SButton,
    SCheckbox,
    SLazyMount,
    skuSizeCommon,
    cancelRelationGoodItem,
    CropImageContainer,
    TipsTextEllipsis,
    RefundAmountDrawer,
    SPopover
  },
  emits: ['closeCancelItemDrawer'],
  data() {
    return {
      showPopover: false,
      checkListCanCheck: [],
      checkListCannotCheck: [],
      checkedAll: false,
      checkedNum: 0,
      exceededQuantityVisible: false,
      statusChange: false,
      exclusionShowFlag: false,
      promotionsList: [],
      idsTem: '', // 退款商品 ids 缓存
      minPriceObj: {},
      showRelationDialog: false,
      relationGoodsList: [],
      relationOptions: {
        type: 2,
        operatorType: 'part',
        billno: '',
        cancelInfo: {}
      },
      isHasCustomizationGoods: false,
      popoverDebounceFnc: null,
      refundAmountObj: {},
      orderCurrency: {},
      asyncCallbacks: [],
      showRefundAmountDrawer: false,
    }
  },
  computed: {
    ...mapState('cancelItemModule', ['orderInfo', 'language', 'matchCompName', 'refundForm', 'abtInfo']),
    popoverStyle(){
      return {
        width: '6.4rem'
      }
    },
    orderGoodsStatus() {
      return orderLogic.orderGoodsStatus(this.language) || []
    },
    isCod() {
      return this.orderInfo.payment_method == 'cod'
    },
    // 当订单满足仅满足“支持整单退”的判断，所有退货商品的勾选框和“全部选择”的勾选框均置灰并默认勾选
    isDisabledChecked() {
      let isDisable 
      if(this.isCod) {
        try {
          const disabledCancel = this.orderInfo.isCanRevokeByUser
          const disabledCancelItem = (this.orderInfo.orderGoodsList.flat(Infinity) || []).some(item => item.isCodOrderCanPartCancel == 1)
          isDisable = !(disabledCancel && disabledCancelItem)
        } catch(e){
          console.log(e)
        }
      } else {
        const disabledCancel = !this.orderInfo.isCommonOrderCanRefund
        const disabledCancelItem = (this.orderInfo.oldOrderGoodsList || []).every(item => !item.isCommonOrderCanPartRefund)
        isDisable =  !disabledCancel && disabledCancelItem
      }
      return isDisable
    },
    showRefundNew() {
      return this.abtInfo?.newAbtTestConfig?.['refundNew'] !== 'off'
    },
    showRefundDeductRemind() {
      return this.abtInfo?.showRefundDeductRemind || false
    },
    isOverMinPrice() {
      const isNoSelected = this.checkListCanCheck.filter(item => item.checked === false)
      let noSelectedTotal = 0
      isNoSelected.forEach(item => {
        let { usdAmount } = item.avgPrice
        let quantity = item.quantity
        noSelectedTotal += Number(usdAmount) * Number(quantity)
      })
      if (this.checkedAll) {
        // 不低于最小值
        return false
      } else {
        // 低于最小值
        return (
          !!this.checkedNum &&
          this.minPriceObj.usdAmount > 0 &&
          noSelectedTotal < this.minPriceObj.usdAmount
        )
      }
    },
    isDisabledBtn() {
      return this.isCod ? !this.checkedNum || this.isOverMinPrice : !this.checkedNum
    },
    showBoxTips() {
      return this.isCod && this.isOverMinPrice
    }
  },
  watch: {
    orderInfo: {
      immediate: true,
      handler() {
        this.init()
      }
    }
  },
  methods: {
    ...mapMutations('cancelItemModule', ['assignCancelItemState', 'changeRootStatus']),
    // 等待用户确认扒回费用
    refundDeductSleep() {
      return new Promise(resolve => {
        this.showRefundAmountDrawer = true
        this.asyncCallbacks = [resolve]
      })
    },
    // 累加扒回金额
    getRefundAmountInfo(refundInfo = {}) {
      this.orderCurrency = refundInfo.orderCurrency
      // 预计退款金额
      const expectedRefund = refundInfo.omsData?.price?.total_refund_price?.amount || 0
      // 商品总折后价
      const refundGoodDiscounted = refundInfo.refund_goods_amount?.discounted?.amount || 0
      // 促销扒回金额
      const refundBillGrill = refundInfo.refund_goods_amount?.refund_bill_grill?.amount || 0
      // Credit Box钱包实扒金额
      const realCreditBoxWallet = refundInfo.omsData?.price?.real_credit_box_wallet?.amount || 0
      // 运费扒回金额
      const realRecFreightt = refundInfo.omsData?.price?.real_rec_freight?.amount || 0
      // 付费会员实际扒回金额
      const realVipGiveWallet = refundInfo.omsData?.price?.real_vip_give_wallet?.amount || 0
      // 扒回总金额
      const amountList = [
        refundBillGrill, 
        realCreditBoxWallet, 
        realRecFreightt, 
        realVipGiveWallet
      ]
      const refundDeductionTotal = sumBy(amountList, i => { return i || 0 })

      this.refundAmountObj = {
        expectedRefund,
        refundGoodDiscounted,
        refundBillGrill,
        realCreditBoxWallet,
        realRecFreightt,
        realVipGiveWallet,
        refundDeductionTotal,
      }
    },
    getGoodsUrl: (goods_url_name, goods_id) => {
      let url =
        '/' +
        goods_url_name.replace('/', '-').split(' ').join('-') +
        '-p-' +
        goods_id +
        // '-cat-' +
        // cat_id +
        '.html'
      return replaceHrefSpecChar({ href: url })
    },
    isDisableCodCheck(goods){  
      return !(this.orderInfo.isCanRevokeByUser && goods.isCodOrderCanPartCancel == 1)
    },
    isShowCustomizationText(data) {
      return data?.customization_flag == 1
    },
    htmlDecode: text => htmlDecode({ text }),
    transformImg,
    init() {
      this.checkedAll = false
      this.checkListCanCheck = []
      this.checkListCannotCheck = []
      this.checkedNum = 0
      this.promotionsList = []
      this.idsTem = ''
      this.minPriceObj = {}
      this.relationOptions.billno = this.orderInfo.billno
      this.isHasCustomizationGoods = false
      this.showPopover = false
      this.popoverClose()
      nextTick(() => {
        this.checkListFun()
      })
      if(this.isDisabledChecked) (
        this.showRefundMeg()
      )
    },
    showRefundMeg(){
      setTimeout(()=>{
        SToast({
          content: this.language.SHEIN_KEY_PWA_33148,
          duration: 3500,
        })
      })
    },
    // *气泡关闭
    popoverClose(){
      if(this.popoverDebounceFnc){
        clearTimeout(this.popoverDebounceFnc)
      }
      this.popoverDebounceFnc = null
    },
    // 全选
    changeCheckAll() {
      if (this.isDisabledChecked) return
      this.checkedAll = !this.checkedAll
      if (this.checkedAll) {
        this.selectItemAll()
        if(!this.showPopover && this.isHasCustomizationGoods){
          this.showPopover = true
          this.popoverClose()
          this.popoverDebounceFnc = setTimeout(() => {
            this.showPopover = false
          }, 3000)
          
        }
      }else{
        this.showPopover = false
        this.popoverClose()
      }
      this.checkListCanCheck.forEach((item, index) => {
        if(this.isCod && item.isCodOrderCanPartCancel != 1) return
        item.checked = this.checkedAll
        this.checkListCanCheck[index] = item
      })
      const isSelectedItem = this.checkListCanCheck.filter(item => item.checked === true)
      this.checkedNum = isSelectedItem.length
    },
    selectItemAll() {
      // bi
      sa('send', { activity_name: 'click_select_goods_all' })
      sa('send', { activity_name: 'click_order_item_select_all' })
    },
    // 单个商品选取
    changeCheck(index, goods = {}) {
      if (this.isDisabledChecked) return 
      this.checkListCanCheck[index].checked = !this.checkListCanCheck[index].checked
      const isSelectedItem = this.checkListCanCheck.filter(item => item.checked === true)
      this.checkedNum = isSelectedItem.length
      this.checkedAll = this.checkListCanCheck.length === this.checkedNum
      if (this.isCod && !!this.checkedNum && !this.isSuccessFetchMinPrice) {
        // cod订单获取最小可退金额
        this.getMinPriceEvt(isSelectedItem, goods)
      }
      // 埋点
      if (this.checkListCanCheck[index].checked) {
        if (!this.isCod) {
          this.selectItemEvt(goods)
        } else if (this.isCod && this.isSuccessFetchMinPrice) {
          this.selectItemEvt(goods, this.isOverMinPrice ? 2 : 1)
        }
      } else {
        this.cancelSelectItem(goods)
      }
    },
    cancelSelectItem(curItem) {
      const { product, sku_code = '' } = curItem
      const { goods_sn, goods_id, attr_value_id } = product || {}
      daEventCenter.triggerNotice({
        daId: '1-19-1-43',
        extraData: {
          billno: this.orderInfo.billno || '',
          goods_id: goods_sn,
          sku_id: goods_id,
          size: attr_value_id,
          order_type: this.isCod ? 1 : 2,
          sku_code
        }
      })
    },
    async getMinPriceEvt(selectItem, curItem) {
      let billno = this.orderInfo.billno
      let order_goods_ids = selectItem.map(item => item.id)
      let params = {
        billno,
        order_goods_ids: JSON.stringify(order_goods_ids)
      }
      let result = await this.getOrderGoodsCodLimit(params)
      let code = result && result.code
      let info = (result && result.info) || {}
      if (code == '0' && info.cod_price_limit) {
        let cod_price_limit = result.info.cod_price_limit
        let { amount, amountWithSymbol, usdAmount } = cod_price_limit.min_local
        this.isSuccessFetchMinPrice = true
        this.minPriceObj = {
          amount: amount,
          amountWithSymbol: amountWithSymbol,
          usdAmount: usdAmount
        }
        this.selectItemEvt(curItem, 1)
      } else {
        this.selectItemEvt(curItem, 2)
      }
    },
    selectItemEvt(curItem = {}, cod_result) {
      const { product, sku_code = '' } = curItem
      const { goods_sn, goods_id, attr_value_id } = product || {}
      daEventCenter.triggerNotice({
        daId: '1-19-1-42',
        extraData: {
          billno: this.orderInfo.billno || '',
          goods_id: goods_sn,
          sku_id: goods_id,
          size: attr_value_id,
          order_type: this.isCod ? 1 : 2,
          cod_result: cod_result || '',
          sku_code
        }
      })
    },
    // 数组结构更改
    async checkListFun() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      let abtInfo = await abtservice.getUserAbtResult({ posKeys: 'IsInfoFront,IsCanRefund' })
      let isInfoFrontAbt = abtInfo?.IsInfoFront?.param == 'on'
      let isCanRefundAbt = abtInfo?.IsCanRefund?.param || 'BBC=on&Shop=on'
      let list = this.orderInfo?.oldOrderGoodsList || []
      let packageList = this.orderInfo?.order_package_info_list || []
      let frontConditionList = packageList
        .filter(item => item.operationProcess == 1)
        .map(item => item.packageNo)
      for (let i = 0; i < list.length; i++) {
        let isCanCancel = this.isDisabledChecked || list[i]?.isCommonOrderCanPartRefund || list[i]?.isCodOrderCanPartCancel
        let noFrontCondition = !(
          isInfoFrontAbt && frontConditionList.includes(list[i]?.reference_number)
        )
        let noB2B2CCondition = !(
          isCanRefundAbt?.includes('BBC=on') && [1, 2, 3].includes(+list[i].pack_detail_status)
        )
        let isNotCustomization = list[i]?.customization_flag != 1
        if(!this.isHasCustomizationGoods && !isNotCustomization){
          this.isHasCustomizationGoods = true
        }
        if (
          isCanCancel &&
          noFrontCondition &&
          noB2B2CCondition &&
          isNotCustomization
        ) {
          let obj = { ...(list[i] || {}), checked: false }
          if (this.isDisabledChecked) {
            obj.checked = true
          }
          this.checkListCanCheck.push(obj)
        } else {
          this.checkListCannotCheck.push(list[i])
        }
      }
      // 当订单满足仅满足“支持整单退”的判断：点击Cancel按钮，跳转退货商品选择页面。所有退货商品的勾选框和“全部选择”的勾选框均置灰并默认勾选；
      if (this.isDisabledChecked) {
        this.checkedAll = true
        this.checkedNum = this.checkListCanCheck.length
      }
    },
    async handleCancelItem() {
      const isSelectedItem = this.checkListCanCheck.filter(item => item.checked === true)
      const codCondition =
        this.checkedAll || (!this.checkedAll && isSelectedItem.length && !this.isOverMinPrice)
      const noCodCondition = isSelectedItem.length
      const isFullCondition = this.isCod ? codCondition : noCodCondition
      if (isFullCondition) {
        let idListTem = isSelectedItem.map(item => `${item.id}`)
        if (idListTem.length < 100) {
          this.changeRootStatus({
            loading: true
          })
          if (this.isCod) {
            let validResult = await this.getOrderGoodsCodLimit({
              billno: this.orderInfo.billno,
              order_goods_ids: JSON.stringify(idListTem)
            })
            let info = validResult.info || {}
            let code = validResult.code
            if (code == '0' && info.is_support_cod == 1) {
              let result = await this.getPartrefundgetinfoEvt({
                billno: this.orderInfo.billno,
                order_good_id_list: idListTem.join(','),
                apply_scene: 1,
                payment_method: this.orderInfo.payment_method
              })
              this.resultInfoEvt(result || {}, idListTem)
            } else {
              this.nextStep(2)
              this.changeRootStatus({
                loading: false
              })
              SToast(this.language.SHEIN_KEY_PWA_18531)
            }
          } else {
            let result = await this.getPartrefundgetinfoEvt({
              billno: this.orderInfo.billno,
              order_good_id_list: idListTem.join(','),
              apply_scene: 1,
              payment_method: this.orderInfo.payment_method
            })
            this.resultInfoEvt(result || {}, idListTem)
          }
        } else {
          this.exceededQuantityVisible = true
        }
      }
    },
    resultInfoEvt(result, idListTem) {
      this.isCod && this.nextStep(1)
      this.changeRootStatus({
        loading: false
      })
      if (result.code != '0') {
        !this.isCod && this.nextStepEvt(0)
        if ([666666, 777777].includes(+result.code)) {
          SToast(result.tips || this.language.SHEIN_KEY_PWA_17115)
        } else {
          this.statusChange = true
        }
      } else {
        let info = result.info || {}
        if (this.showRefundDeductRemind) {
          this.getRefundAmountInfo(info)
        }
        let isAddOrderList = info?.refund_goods?.filter(item => item.is_added_order == 1)
        let isLowValueList = info?.refund_goods?.filter(item => item.is_low_value == 1)
        let isRiskPunish = info?.refund_goods?.some(item => item?.is_risk_punish === 1)
        const { omsData = {}, refund_goods_amount = {} } = info
        const { price } = omsData || {}
        const { real_rec_freight, real_vip_give_wallet, real_credit_box_wallet } = price || {}
        const refundAmountDetails = []
        // 展示运费扒回金额 
        if (real_rec_freight?.amount > 0) {
          refundAmountDetails.push(1)
        }
        // 展示促销扒回金额
        if (refund_goods_amount?.refund_bill_grill?.amount > 0) {
          refundAmountDetails.push(2)
        }
        // 付费会员钱包扒回金额
        if (real_vip_give_wallet?.amount > 0) {
          refundAmountDetails.push(3)
        }
        // credit box扒回金额
        if (real_credit_box_wallet?.amount > 0) {
          refundAmountDetails.push(4)
        }
        if (window.SaPageInfo) {
          SaPageInfo.page_param = {
            ...SaPageInfo.page_param,
            refund_amount_details: refundAmountDetails.length ? refundAmountDetails.join(',') : '-'
          }
          appEventCenter.emit('pageOnload')
        }

        if (
          isAddOrderList?.length
          || isLowValueList?.length
          || info.promotionBundleFlag > 0
          || isRiskPunish
        ) {
          !this.isCod && this.nextStepEvt(0)
          let refundGoods = info?.refund_goods?.filter(
            item => !idListTem.includes(`${item.order_goods_id}`)
          )
          this.showRelationDialog = true
          this.relationGoodsList = refundGoods
          this.relationOptions = {
            ...this.relationOptions,
            billno: this.orderInfo.billno,
            cancelInfo: info
          }
          this.exposeCombinedRefundTip('1-19-1-76')
        } else {
          !this.isCod && this.nextStepEvt(1)
          this.emitRefundForm(info)
        }
      }
    },
    async confirmCancelOrder(cancelInfo = {}, key) {
      this.showRelationDialog = false
      this.exposeCombinedRefundTip('1-19-1-77', key)
      this.changeRootStatus({
        loading: true
      })
      let idList = cancelInfo?.refund_goods?.map(item => `${item.order_goods_id}`)
      this.checkListCanCheck?.forEach(item => {
        if (idList.includes(`${item.id}`)) {
          item.checked = true
        }
      })
      let res = await this.getPartrefundgetinfoEvt({
        billno: this.orderInfo.billno,
        order_good_id_list: idList.join(','),
        apply_scene: 1,
        payment_method: this.orderInfo.payment_method
      })
      this.changeRootStatus({
        loading: false
      })
      if (res.code == '0') {
        this.emitRefundForm(res.info || {})
        sa('send', { activity_name: 'expose_popup_order_item_confirm' })
      } else {
        let textMap = {
          666666: this.language.SHEIN_KEY_PWA_17115,
          777777: this.language.SHEIN_KEY_PWA_17115
        }
        this.showMsg({
          msg: textMap[res?.code] || this.language.SHEIN_KEY_PWA_15409
        })
      }
    },
    updateShowValue() {
      this.showRelationDialog = false
    },
    exposeCombinedRefundTip(type, clickType) {
      let mapBillno = this.relationGoodsList?.map(item => item.billno)
      let mapGoodsId = this.relationGoodsList?.map(item => item.order_goods_id)
      let { billno } = this.relationOptions || {}
      let baseParams = {
        order_no: billno,
        relation_billno: mapBillno.join(','),
        relation_goods_id: mapGoodsId.join(',')
      }
      if (clickType) {
        baseParams = { ...baseParams, type: clickType }
      }
      daEventCenter.triggerNotice({
        daId: type,
        extraData: baseParams
      })
    },
    /**
     * @description 初始化部分refundForm数据，用于refund-item页使用
     * @date 2021-09-27 17:28
     * @param {object} info
     */
    async emitRefundForm(info) {
      // 扒回金额大于0，弹窗提示用户
      if (this.refundAmountObj.refundDeductionTotal > 0) {
        const isAgree = await this.refundDeductSleep()
        if (!isAgree) {
          return false
        } 
      }
      const { orderCurrency } = info || {}
      let refundForm = {
        data: info,
        orderCurrency
      }
      // 更新refundForm
      this.assignCancelItemState({
        isCheckAll: this.checkedAll,
        // 更新refundForm
        refundForm: { ...this.refundForm, ...refundForm },
        // 组件切换
        matchCompName: 'refund-item',
        // 写入历史组件栈，用于返回操作
        compStacks: [this.matchCompName]
      })
    },
    // 提交报错，返回订单列表页
    closeCancelItemDrawer() {
      this.statusChange = false
      this.$emit('closeCancelItemDrawer')
    },
    async getOrderGoodsCodLimit(params) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/orderGoodsCodLimit/get',
        data: params
      })
      return res || {}
    },
    async getPartrefundgetinfoEvt(params) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getRefundGoodsInfo/query',
        data: params,
        headers: { blackbox: window?._fmOpt?.__blackbox || '' }
      })
      return res || {}
    },
    nextStep(cod_result) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-44',
        extraData: {
          billno: this.orderInfo.billno || '',
          order_type: this.isCod ? 1 : 2,
          cod_result: cod_result || ''
        }
      })
    },
    nextStepEvt(value) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-47',
        extraData: {
          value: value
        }
      })
    },
    getStatusText(item) {
      const BBCText = orderLogic.getBBCSpecailText({
        orderGoodsList: [item] || [],
        shipping_country_id: this.orderInfo?.shipping_country_id,
        language: this.language
      })

      return BBCText?.orderStatusText || this.orderGoodsStatus[item.orderGoodsStauts] || ''
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-item-list-checkbox {
  box-sizing: border-box;
  padding: 0;
}
.order-item-list-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: @color_brand;
  .border-dpr(border, 2px, transparent);
  padding: 0 0.4rem;
  color: #fff;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: initial;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.order-item-list-btn-xs {
  padding: 0 0.21rem;
  height: 0.64rem;
  line-height: 0.64rem;
  .font-dpr(24px);
}
.item-list-box {
  margin-top: 0.32rem;
  background: #fff;
  padding-bottom: 1.6rem;
  .item-list {
    border-bottom: 1px solid rgba(255, 239, 237, 1);
    box-sizing: border-box;
    height: 3.17rem;
    overflow: hidden;
    display: flex;
    padding: 0.2666rem 0.2666rem 0.4rem;
    position: relative;
    .obscuration {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; /* stylelint-disable-line declaration-property-value-blacklist */
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
    }
    div {
      flex: none;
    }
    .check-box {
      display: flex;
      width: 0.93rem;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      .check-all {
        box-sizing: border-box;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px solid #999;
      }
    }
    .item-img-box {
      flex: none;
      width: 1.97rem;
      box-sizing: border-box;
      .item-a {
        display: block;
        width: 100%;
        height: 2.64rem;
        .item-img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .des-box {
      position: relative;
      box-sizing: border-box;
      .padding-l(0.266rem);
      flex: auto;
      width: 100%;
      .font-dpr(24px);
      font-weight: 400;
      line-height: 1.2em;
      color: rgba(51, 51, 51, 1);
      .g-name {
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        a {
          text-decoration: none;
        }
      }
      .size-price {
        margin-top: 8/75rem;
        color: #666;
        .flexbox();
        .g-size {
          width: 70%;
          .txt-l();
          white-space: initial;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .g-price {
          width: 30%;
          .txt-r();
        }
      }
      .g-num {
        margin-top: 8/75rem;
        .txt-r();
      }
      .g-item-status {
        .txt-r();
        font-weight: bold;
        margin-top: 1em;
      }
    }
  }
  .item-no-list {
    border-bottom: 1px solid rgba(255, 239, 237, 1);
    .refund-item__customization {
      padding: 0 24/75rem 0 1.196rem;
      box-sizing: border-box;
      margin-bottom: 20/75rem;
    }
    .item-list__content {
      box-sizing: border-box;
      height: 3.17rem;
      overflow: hidden;
      display: flex;
      padding: 0.2666rem 0.2666rem 0.4rem;
      position: relative;
      .obscuration {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; /* stylelint-disable-line declaration-property-value-blacklist */
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
      }
      div {
        flex: none;
      }
      .check-box {
        display: flex;
        width: 0.93rem;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        .check-all {
          box-sizing: border-box;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          border: 2px solid @sui_color_gray_weak1;
          background-color: @sui_color_gray_weak2
        }
      }
      .item-img-box {
        flex: none;
        width: 1.97rem;
        box-sizing: border-box;
        .item-a {
          display: block;
          width: 100%;
          height: 2.64rem;
          .item-img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .des-box {
        position: relative;
        box-sizing: border-box;
        .padding-l(0.266rem);
        flex: auto;
        width: 100%;
        .font-dpr(24px);
        font-weight: 400;
        line-height: 1.2em;
        color: rgba(51, 51, 51, 1);
        .g-name {
          color: #666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          a {
            text-decoration: none;
          }
        }
        .size-price {
          margin-top: 8/75rem;
          color: #666;
          .flexbox();
          .g-size {
            width: 70%;
            .txt-l();
            white-space: initial;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .g-price {
            width: 30%;
            .txt-r();
          }
        }
        .g-num {
          margin-top: 8/75rem;
          .txt-r();
        }
        .g-item-status {
          .txt-r();
          font-weight: bold;
          margin-top: 1em;
        }
      }
    }
  }
}
.cancel-item-all__box {
  position: fixed;
  z-index: 3; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translateZ(3px);
  left: 0;
  bottom: 0;
  width: 100%;
  // height: 1.6rem;
  box-sizing: border-box;
  border-top: 1px solid rgba(229, 229, 229, 1);
  padding: 0.4rem 0.4rem;
  background: #fff;
  .tips-ctx {
    padding: 16/75rem;
    color: #222;
    background-color: #d9eaff;
    margin-bottom: 20/75rem;
  }
  .selected-ctx {
    display: flex;
    align-items: center;
    .checkout-box {
      flex: auto;
      width: 6.64rem;
      display: flex;
      align-items: center;
      .check-all {
        box-sizing: border-box;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px solid #999;
        .margin-r(0.133rem);
      }
    }
    .check-all-btn {
      & when (@IS_RW) {
        border-radius: 0.58rem;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.item-list-wrap {
  // height:100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .item-list-box {
    margin-top: 24/75rem;
    background: #fff;
    padding-bottom: 0;
    overflow-y: auto;
    flex-grow: 1;
  }
  .cancel-item-all__box {
    position: initial;
    transform: none;
  }
}

.custom-exclusion-drawer {
  background: #fff;
  &__content {
    padding: 24/75rem;
  }
  .sui-drawer__normal-footer {
    height: auto;
    padding: 24/75rem;
  }
  .exclusion-des {
    box-sizing: border-box;
    padding: 20/75rem 30/75rem;
    background: #fafafa;
    font-size: 12px;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 1.2;
    text-align: center;
  }
  .refund-goods {
    margin-top: 0.2rem;
    max-height: 7rem;
    overflow-y: scroll;
    .goods {
      display: -webkit-box;
      display: -moz-box;
      display: flex;
      display: -ms-flexbox;
      position: relative;
      margin-bottom: 0.106rem;
    }
    .goods-img {
      margin-right: 0.2rem;
      img {
        width: 2rem;
      }
    }
    .goods-info {
      flex: 1;
      color: #999;
    }
    .name {
      white-space: initial;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .size {
      margin-top: 0.106rem;
      white-space: initial;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      span {
        display: inline-block;
      }
    }
    .goods-num {
      position: absolute;
      bottom: -0.1rem;
      right: 0;
    }
  }
}

.check-box__input {
  :deep(.sui-checkbox__label-select) {
    border-color: #222222;
  }
}
</style>
