<template>
  <div
    ref="timeContainer"
    class="time-container"
  >
    <div class="time-content">
      <Icon
        name="sui_icon_caution_12px_2"
        class="time-icon"
        size="12px"
        color="#959595"
      />
      <slot :class-name="className"></slot>
    </div>

    <div
      v-if="showHandleIcon"
      class="icon-container"
    >
      <span
        class="handle-text"
        @click="handleText"
      > {{ language.SHEIN_KEY_PWA_27619 }}
        <Icon
          name="sui_icon_more_down_12px_2"
          class="more-icon"
          size="12px"
          color="#767676"
        />
      </span>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
export default defineComponent({
  name: 'TipsTextEllipsis',
  components: {
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isExpanded: false,
      showHandleIcon: false
    }
  },
  computed: {
    className() {
      const classList = []
      if (this.showHandleIcon) {
        classList.push('over-text')
      }
      if (this.isExpanded) {
        classList.push('expanded')
      }
      return classList.join(' ')
    }
  },
  updated() {
    if (!this.showHandleIcon) {
      this.showMoreIcon()
    }
  },
  mounted() {
    if (!this.showHandleIcon) {
      this.showMoreIcon()
    }
  },
  methods: {
    handleText() {
      this.isExpanded = !this.isExpanded
      this.showHandleIcon = false
    },
    showMoreIcon() {
      nextTick(() => {
        const container = this.$refs.timeContainer
        if (container && container.scrollHeight > container.clientHeight) {
          this.showHandleIcon = true
        }
      })
    }
  },
})
</script>
<style lang="less" scoped>
.time-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  overflow: hidden;
  padding: 16 /75rem;
  box-sizing: border-box;
  background: #f6f6f6;
}
.time-text {
  color: @sui_color_gray_dark3;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5em;
  max-height: 3em;

  &.expanded {
    max-height: none;
  }
}
.over-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.time-content {
  display: flex;
  flex: 1;
  align-items: flex-start;
  .time-icon {
    margin-right: 6/75rem;
    margin-top: 6/75rem;
  }
}

.icon-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .handle-text {
    color: @sui_color_gray_dark3;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    .more-icon {
      margin-left: 4/75rem;
    }
  }
}
</style>
